import { ManageStrictGuard } from './../../../+b2b/auth/manage-strict-guard.service.';
import { PermissionGuard } from './../../../+b2b/auth/permission-guard.service';
import { DirectorGuard } from './../../../+b2b/auth/director-guard.service';
import { UserService } from 'app/+b2b/user/user.service';
import { Component, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { LoginInfoComponent } from "../../user/login-info/login-info.component";
import { ManageGuard } from '../../../+b2b/auth/manage-guard.service';
import { AuthGuard } from '../../../+b2b/auth/auth-guard.service';
import { AdminGuard } from '../../../+b2b/auth/admin-guard.service';
import * as moment from "moment";
import "moment/locale/pl";
import { environment } from 'environments/environment';
import { LocalStorageService } from 'ngx-store';

@Component({

    selector: 'sa-navigation',
    templateUrl: './navigation.component.html',
    styles: [':host ::ng-deep nav ul li ul li a {padding-top: 4px; padding-bottom: 4px;font-size: 13px;}']
})
export class NavigationComponent implements OnInit, AfterViewInit {

    isCategoriesDataLoaded = false
    user: any

    b2b$: Promise<boolean>
    managerBonusesGuard$: Promise<boolean>
    raportsExcelGuard$: Promise<boolean>
    directorBonusesGuard$: Promise<boolean>
    managerBonusesRaportGuard$: Promise<boolean>
    invoicesGuard$: Promise<boolean>
    raports$: Promise<boolean>
    bonuses$: Promise<boolean>
    developer$: Promise<boolean>
    importPremie$: Promise<boolean>
    superAdmin$: Promise<boolean>
    recipes$: Promise<boolean>
    promotions$: Promise<boolean>
    promotionsAdmin$: Promise<boolean>
    crm$: Promise<boolean>
    crmAdmin$: Promise<boolean>
    dhSettings$: Promise<boolean>
    premiumGenerate$: Promise<boolean>
    shippingAdmin$: Promise<boolean>
    shippingManager$: Promise<boolean>
    dyeing$: Promise<boolean>
    dyeingClient$: Promise<boolean>
    dyeingConfiguration$: Promise<boolean>

    pastMonths: any[] = []
    allMonths: any[] = []

    years: any[] = []
    years2: any[] = []

    production

    isServiceWork = false

    constructor(
        public authGuard: AuthGuard,
        public manageGuard: ManageGuard,
        public manageStrictGuard: ManageStrictGuard,
        public adminGuard: AdminGuard,
        public directorGuard: DirectorGuard,
        private userService: UserService,
        public permissionGuard: PermissionGuard,
        private localStorageService: LocalStorageService
    ) {
    }

    async ngOnInit() {

        this.production = environment.production

        this.pastMonths = this.getPastMonths()
        this.allMonths = this.getAllMonths()

        const previousYear = +moment().format('YYYY') - 1

        this.years = this.getYears(2019)
        this.years2 = this.getYears2()

        this.userService.getLoginInfo(true).subscribe(user => {
            if (user) {
                this.user = user
            }
        })

        this.isServiceWork = await this.permissionGuard.isServiceWork().toPromise()

        const permissions = this.localStorageService.load('permissions').value || await this.permissionGuard.getAllPermissions().toPromise()


        if (permissions) {
            this.b2b$ = this.permissionGuard.canActivateCustom('DOSTEP_DO_B2B', permissions)
            this.managerBonusesGuard$ = this.permissionGuard.canActivateCustom('PRM_HANDLOWCY_PREMIE', permissions)
            this.raportsExcelGuard$ = this.permissionGuard.canActivateCustom('RAPORTY_RAPORTY_EXCEL', permissions)
            this.directorBonusesGuard$ = this.permissionGuard.canActivateCustom('PRM_DYREKTORZY_PREMIE', permissions)
            this.managerBonusesRaportGuard$ = this.permissionGuard.canActivateCustom('PRM_RAPORT_PREMIE', permissions)
            this.invoicesGuard$ = this.permissionGuard.canActivateCustom('PRM_POKAZ_FAKTURY', permissions)
            this.raports$ = this.permissionGuard.canActivateCustom('PRM_RAPORTY', permissions)
            this.bonuses$ = this.permissionGuard.canActivateCustom('BONUS_EDIT_USER_CONFIG', permissions)
            this.developer$ = this.permissionGuard.canActivateCustom('DEVELOPER', permissions)
            this.importPremie$ = this.permissionGuard.canActivateCustom('IMPORT_PREMIE_DATA', permissions)
            this.superAdmin$ = this.permissionGuard.canActivateCustom('SUPERADMIN', permissions)
            // this.recipes$ = this.permissionGuard.canActivateCustom('RECEPTURY', permissions)
            this.promotions$ = this.permissionGuard.canActivateCustom('PROMOCJE', permissions)
            this.promotionsAdmin$ = this.permissionGuard.canActivateCustom('PROMOCJE_ADMIN', permissions)
            this.crm$ = this.permissionGuard.canActivateCustom('CRM', permissions)
            this.crmAdmin$ = this.permissionGuard.canActivateCustom('CRM_ADMIN', permissions)
            this.dhSettings$ = this.permissionGuard.canActivateCustom('DH_SETTINGS', permissions)
            this.premiumGenerate$ = this.permissionGuard.canActivateCustom('GENEROWANIE_PREMII', permissions)
            this.shippingAdmin$ = this.permissionGuard.canActivateCustom('SPEDYCJA_KOORDYNATOR', permissions)
            this.shippingManager$ = this.permissionGuard.canActivateCustom('SPEDYCJA_OBSLUGA_POJAZDOW', permissions)
            this.dyeing$ = this.permissionGuard.canActivateCustom(['BARWIENIA_LABORATORIUM', 'BARWIENIA_KLIENT', 'BARWIENIA_KONFIGURACJA', 'BARWIENIA_PIOTROWICE', 'BARWIENIA_KONFIGURACJA_PIOTROWICE'], permissions)
            this.dyeingClient$ = this.permissionGuard.canActivateCustom('BARWIENIA_KLIENT', permissions)
            this.dyeingConfiguration$ = this.permissionGuard.canActivateCustom(['BARWIENIA_KONFIGURACJA', 'BARWIENIA_KONFIGURACJA_PIOTROWICE'], permissions)

        }
    }

    ngAfterViewInit() {

    }

    handleCategoriesDataLoaded(event) {
        // console.log(event)
        this.isCategoriesDataLoaded = event
    }

    getPastMonths() {
        const actualMonth = +moment().format('MM')
        const pastMonths = []
        for (let i = 1; i < actualMonth; i++) {
            pastMonths.push({
                no: i,
                name: moment(i, 'MM').format('MMMM')
            })
        }
        return pastMonths
    }
    getAllMonths() {
        const allMonths = []
        for (let i = 1; i < 13; i++) {
            allMonths.push({
                no: i,
                name: moment(i, 'MM').format('MMMM')
            })
        }
        return allMonths
    }
    getYears(startYear = 2019, stopYear = +moment().format('YYYY')) {
        const years = []
        for (let i = startYear; i <= stopYear; i++) {
            years.push(i)
        }
        return years
    }
    getYears2(startYear = +moment().format('YYYY') - 1) {
        const actualYear = +moment().format('YYYY')
        const years = []
        for (let i = startYear; i <= actualYear; i++) {
            years.push(i)
        }
        return years
    }

}

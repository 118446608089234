import { Injectable } from '@angular/core';
import { B2B_DI_CONFIG } from './+b2b/b2b.config';
import { HttpClient } from '@angular/common/http';

export type InternalStateType = {
  [key: string]: any
};

@Injectable()
export class AppState {
  _state: InternalStateType = {};

  private url = B2B_DI_CONFIG.server.url

  constructor(private http: HttpClient) {

  }

  // already return a clone of the current state
  get state() {
    return this._state = this._clone(this._state);
  }
  // never allow mutation
  set state(value) {
    throw new Error('do not mutate the `.state` directly');
  }


  get(prop?: any) {
    // use our state getter for the clone
    const state = this.state;
    return state.hasOwnProperty(prop) ? state[prop] : state;
  }

  set(prop: string, value: any) {
    // internally mutate our state
    return this._state[prop] = value;
  }


  private _clone(object: InternalStateType) {
    // simple object clone
    return JSON.parse(JSON.stringify(object));
  }

  getVersion() {
    return this.http.get<any>(this.url + 'getVersion' + '/')
  }

}

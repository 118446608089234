import { OnInit } from '@angular/core';
import { Component, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  Router,
  // import as RouterEvent to avoid confusion with the DOM Event
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  ActivatedRoute
} from '@angular/router'
import { SpinnerModule } from 'angular2-spinner/dist';
import { filter, map } from "rxjs/operators";

@Component({
  selector: 'app-root',
  template: `<router-outlet>
                <div class="loader loading-overlay" *ngIf="loading">
                  <div class="row" style="height: 100%">
                    <div class="col-xs-12 col-sm-12" style="height: 100%">
                      <div class="spinner-container vertical-align" style="min-height: 800px;">
                        <spinner [tickness]="3" [size]="50"></spinner>
                      </div>
                    </div>
                  </div>
                </div>
              </router-outlet>
              <simple-notifications></simple-notifications>
            `
})
export class AppComponent implements OnInit {
  public title = 'app works!';

  loading = true


  public constructor(
    private router: Router,
    private viewContainerRef: ViewContainerRef,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    const routerEvents = router.events

    routerEvents.subscribe((event: RouterEvent) => {
      setTimeout(() => {
        this.navigationInterceptor(event)
      })
    })

    routerEvents.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data && child.snapshot.data['pageTitle']) {
            return child.snapshot.data['pageTitle'];
          } else {
            return null;
          }
        }
        return null;
      })
    ).subscribe((data: any) => {
      if (data) {
        this.titleService.setTitle('B2B - ' + data);
      }
    });
  }

  ngOnInit() {
    //  import('arrive/src/arrive.js')
    //  import('assets/js/custom.js')

  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true
    }
    if (event instanceof NavigationEnd) {
      this.loading = false
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false
    }
    if (event instanceof NavigationError) {
      this.loading = false
    }
  }


}

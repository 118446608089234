import { Directive, Input, ElementRef } from '@angular/core';

declare var $: any;

@Directive({
  selector: '[saUiValidate]'
})
export class UiValidateDirective {

  @Input() saUiValidate: any;

  constructor(private el: ElementRef) {
    Promise.all([
      import("jquery-validation/dist/jquery.validate.js"),
      import("jquery-validation/dist/additional-methods.js")
    ])
      .then(() => {
        this.attach()
      });
  }


  attach() {
    const $form = $(this.el.nativeElement)
    const validateCommonOptions = {
      rules: {},
      messages: {},
      errorElement: 'em',
      errorClass: 'invalid',
      highlight: (element, errorClass, validClass) => {
        $(element).addClass(errorClass).removeClass(validClass);
        $(element).parent().addClass('state-error').removeClass('state-success');
      },
      unhighlight: (element, errorClass, validClass) => {
        $(element).removeClass(errorClass).addClass(validClass);
        $(element).parent().removeClass('state-error').addClass('state-success');
      },

      errorPlacement: (error, element) => {
        if (element.parent('.input-group').length) {
          error.insertAfter(element.parent());
        } else {
          error.insertAfter(element);
        }
      }
    };

    $form.find('[data-smart-validate-input], [smart-validate-input]').each(function () {
      var $input = $(this), fieldName = $input.attr('name');

      validateCommonOptions.rules[fieldName] = {};

      if ($input.data('required') != undefined) {
        validateCommonOptions.rules[fieldName].required = true;
      }
      if ($input.data('email') != undefined) {
        validateCommonOptions.rules[fieldName].email = true;
      }

      if ($input.data('maxlength') != undefined) {
        validateCommonOptions.rules[fieldName].maxlength = $input.data('maxlength');
      }

      if ($input.data('minlength') != undefined) {
        validateCommonOptions.rules[fieldName].minlength = $input.data('minlength');
      }

      if ($input.data('message')) {
        validateCommonOptions.messages[fieldName] = $input.data('message');
      } else {
        Object.keys($input.data()).forEach((key) => {
          if (key.search(/message/) == 0) {
            if (!validateCommonOptions.messages[fieldName])
              validateCommonOptions.messages[fieldName] = {};

            var messageKey = key.toLowerCase().replace(/^message/, '')
            validateCommonOptions.messages[fieldName][messageKey] = $input.data(key);
          }
        });
      }
    });

    $form.validate($.extend(validateCommonOptions, this.saUiValidate))

    $.validator.methods.greaterThen = function (value, element, param) {
      let globalizedValue = value.replace(",", ".");
      globalizedValue = globalizedValue.replace(" ", "");
      return this.optional(element) || globalizedValue > param
    }

    $.validator.methods.postcode = function (value, element, param) {
      return this.optional(element) || /^[0-9]{2}-[0-9]{3}$/.test(value);
    }
    $.validator.addMethod("nip", function (value) {
      "use strict";

      if (!value) {
        return true
      }

      const e = value.replace(/-/gu, "");

      if (!/^[0-9]{10}$/u.test(e)) return !1;
      const r = String(e).split(""),
        n = [6, 5, 7, 2, 3, 4, 5, 6, 7].map(((t, e) => t * parseInt(r[e], 10)));
      let s = 0;
      n.forEach((t => {
        s += t
      }));
      const u = s % 11;
      return parseInt(r[9], 10) === u
     
    }, "Please specify a valid NIP number.");





  }

}

import { Category } from './../models/category.model';
import { B2B_DI_CONFIG } from "./../b2b.config";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";


@Injectable()
export class CategoryService {
  // //private url = location.protocol + '//' + location.hostname + (location.port ? ':'+location.port : '') + '/' +  'assets/api' + '/b2b/categories/list.json'
  // // private url = 'http://192.168.15.5:8083/getTowaryGrupyListTree'
  // private url = 'http://192.168.15.5:8083/getTowaryGrupyListTree/'

  private url = B2B_DI_CONFIG.server.url;

  private _categories: any;
  private _foreignBrands: any;

  constructor(private http: HttpClient) {}

  getAll(): Promise<any> {
    return (
      this.http
        .get(this.url + 'getTowaryGrupyListTree/')
        // .catch(e => {
        //   if (e.status === 401) {
        //       return Observable.empty<Response>();
        //   }
        //   // do any other checking for statuses here
        // })
        .toPromise()
        .then(data => (this._categories = data))
    );
  }

  get categories(): any {
    return this._categories
      .sort((a, b) => {
        return a.kod < b.kod ? -1 : 1;
      })
      .map(d => {
        if (d.childs.length) {
          d.childs
            .sort((a, b) => {
              return a.kod < b.kod ? -1 : 1;
            })
            .map(child => {
              return this.sortIndexRemove(child);
            });
        }

        return this.sortIndexRemove(d);
      });
  }

  async getCategoryFromId(_id) {

    const id = +_id

    let mainCategory: Category
    let subCategory: Category

    if (!id || id === 2200) {
      return {
        // mainCategory: {
        //   kod: 'Katalog produktów'
        // }
      };
    }
    const categories = await this.getAll();

    let temp;

    mainCategory = categories.filter(d => d.id === id)[0];

    if (!mainCategory) {
      categories.map(category => {

        temp = category.childs.filter(c => c.id === id);

        if (temp.length > 0) {
          subCategory = this.sortIndexRemove(temp[0]);
          mainCategory = this.sortIndexRemove(category);
        }
      });
    } else {
      mainCategory = this.sortIndexRemove(mainCategory)
    }

    return {mainCategory: mainCategory, subCategory: subCategory}
  }

  private sortIndexRemove(record) {
    const kod = record.kod.split('_');
    record.kod = kod[1];
    return record;
  }

  async getChildren(id: number) {
    const categories = await this.getAll();
    return categories.filter(d => d.id === id).map(d => {
      const children = [];
      if (d.childs.length) {
        d.childs.forEach(element => {
          children.push(element.id);
        });
      }
      return children;
    })[0];
  }

  getForeignBrands(): Promise<any> {
    return this.http
      .get<any>(this.url + 'getMarkiObce/')
      .pipe(
        map(brands => {
          return brands.map(brand => {
            return {
              value: brand.id,
              text: brand.name
            };
          });
        })
      )
      .toPromise();
  }
}

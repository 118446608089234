import { ChangePasswordComponent } from './change-password/change-password.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './../auth/auth-guard.service';
import { UserProfileComponent } from './user-profile/user-profile.component';

export const userRoutes: Routes = [
  {
    path: 'profil',
    component: UserProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'zmiana-hasla',
    data: { pageTitle: 'Zmiana hasła' },
    component: ChangePasswordComponent
  },

];


export const userRouting = RouterModule.forChild(userRoutes)

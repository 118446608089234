import { Injectable } from '@angular/core';
import { B2B_DI_CONFIG } from './../b2b.config';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class BlogService {

    private url = B2B_DI_CONFIG.server.url

    constructor(private http: HttpClient) { }


    getPosts() {
        return this.http.get<any>(this.url + 'getPages/')
    }
    getAlerts() {
        return this.http.get<any>(this.url + 'getPagesPasek/')
    }
    update(page) {
        return this.http.post(this.url + 'savePage/', page);
    }

}

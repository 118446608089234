
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { B2B_DI_CONFIG } from './../b2b.config';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NotificationsService } from 'angular2-notifications';
import { PermissionGuard } from './permission-guard.service';
import { LocalStorageService } from 'ngx-store';

@Injectable()
export class AuthService {
  isLogged = false;

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  private url1 = `${location.protocol}//${location.hostname}${location.port ? ':' + location.port : ''}/assets/api/b2b/users/`
  private url = B2B_DI_CONFIG.server.url

  private refreshTokenCall

  refreshTime = 2400

  tokenActive = 18008

  constructor(
    private http: HttpClient,
    private router: Router,
    private jwtHelperService: JwtHelperService,
    private notificationsService: NotificationsService,
    private permissionGuard: PermissionGuard,
    private localStorageService: LocalStorageService
  ) {}

  login(email: string, password: string, previousRoute: string, deviceInfo = null) {
    return this.http.post<any>(this.url + 'login/', { email: email, password: password, deviceInfo: deviceInfo }).pipe(
      map(response => {
        const result = response;
        if (result && result['token']) {
          const userInfo = {
            'id': result['user_id'],
            'permission': result['permission'],
            'name': result['name'],
            'lname': result['lname'],
            'discount': result['rabat'],
            'discountCash': result['lgn_rabatGot'],
            'discountTrans': result['lgn_rabatTrans'],
            'discountTransQuota': result['lgn_rabatTransKw'],
            'region':  result['region'],
            'parent': result['lgn_pod_id'],
            'network': result['siec'],
            'invoicesAccess': result['lgn_invoices']
          }

          // const token: string = result['token']
          // if (token) {
          //   const user = this.jwtHelperService.decodeToken(token)
          //   console.log(user)
          //   // userInfo.network = user
          // }
      

          localStorage.setItem('access_token', result['token']);
          localStorage.setItem('user-info', JSON.stringify(userInfo));

          this.permissionGuard.getAllPermissions()
          .subscribe(
            permissions => this.localStorageService.set('permissions', permissions)
          )

          return previousRoute;
        }
        return false;
      }),
      catchError(e => {
        if (e.status === 401) {
            return observableThrowError('Unauthorized');
          } else {
            return observableThrowError('Błąd połączenia z serwerem, spróbuj za chwilę');
          }
        }
      ),)
    // return Observable.of(true).delay(1000).do(val => this.isLoggedIn = true);
  }

  logout(): void {
    this.isLogged = false;
    setTimeout(() => {
      location.reload();
    }, 0);

  }

  isLoggedIn() {
    const token: string = this.jwtHelperService.tokenGetter()

    // return true
    if (!token) {
      return false
    }

    const current_time = new Date().getTime() / 1000
    const tokenDecode = this.jwtHelperService.decodeToken(token)

    const dateExp =  this.jwtHelperService.getTokenExpirationDate(token)

    if (dateExp) {
      const tokenExpiredDate = this.jwtHelperService.getTokenExpirationDate(token).getTime() / 1000

      const diff = tokenExpiredDate - current_time

      const refreshStart = this.tokenActive - diff

      if (refreshStart > this.refreshTime && diff > 0) {
        this.refreshToken()
      }
    }
    const tokenExpired: boolean = this.jwtHelperService.isTokenExpired(token)
    
    return !tokenExpired
  }


  hasPermission(id: number, minPermission: number) {
    const token: string = this.jwtHelperService.tokenGetter()
    if (token) {
      const user = this.jwtHelperService.decodeToken(token)
      const permissionTable = user.permissionTable.map(Number)
      if (permissionTable.includes(id) && ((minPermission > 0 && user.permission >= minPermission) || (user.permission == 0 && minPermission == 0 ))) {
        return true
      }
    }

    return false
  }


  isManager() {
    const token: string = this.jwtHelperService.tokenGetter()
    if (token) {
      const user = this.jwtHelperService.decodeToken(token)
      
      if (user.permission > 0) {
        return true
      }
    }

    return false
  }

  isManagerStrict() {
    const token: string = this.jwtHelperService.tokenGetter()
    if (token) {
      const user = this.jwtHelperService.decodeToken(token)
      if (user.permission == 1) {
        return true
      }
    }

    return false
  }

  isDirector() {
    const token: string = this.jwtHelperService.tokenGetter()
    if (token) {
      const user = this.jwtHelperService.decodeToken(token)
      if (user.permission > 1) {
        return true
      }
    }

    return false
  }

  isDirectorStrict() {
    const token: string = this.jwtHelperService.tokenGetter()
    if (token) {
      const user = this.jwtHelperService.decodeToken(token)
      if (user.permission == 2) {
        return true
      }
    }

    return false
  }

  
  isAdmin() {
    const token: string = this.jwtHelperService.tokenGetter()
    if (token) {
      const user = this.jwtHelperService.decodeToken(token)
      if (user.permission > 2) {
        return true
      }
    }

    return false
  }

  /* do testow */

  getUsers(): Observable<any> {
    return this.http.get( this.url1 + 'list.json').pipe(
    map((data) => data['data']));
  }

  refreshToken() {
    if (!this.refreshTokenCall) {
      this.refreshTokenCall = this.http.post(this.url + 'refreshSession/', null)
      .subscribe(response => {
        if (response) {
          this.saveTokenInLocalStorage(response['token'])
          this.refreshTokenCall = null
        }
       })
    }

    return this.refreshTokenCall;
  }


  public getToken(): string {
    return localStorage.getItem('access_token');
  }

  private saveTokenInLocalStorage(token) {
    localStorage.setItem('access_token', token);
  }

  public badBrowserInfo(deviceInfo: any) {
    return this.http.post<any>(this.url + 'badBrowserInfo/', { deviceInfo: deviceInfo })
  }
}

import { environment } from './../../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { AuthService } from 'app/+b2b/auth/auth.service';
import { UserService } from '../../../+b2b/user';
import('arrive/src/arrive.js');

declare var $: any;

@Component({
  selector: 'sa-header',
  templateUrl: './header.component.html',
  styles: [`
            :host ::ng-deep .profile-menu > li {
              min-width: 160px;
            }
            :host ::ng-deep .profile-menu > li > a {
              text-align: center;
            }
            :host ::ng-deep .profile-menu > li > a,
            :host ::ng-deep .profile-menu > li > a:focus,
            :host ::ng-deep .profile-menu > li > a:hover{
              background-color: unset!important;
            }
            :host ::ng-deep .nav .open>a,
             :host ::ng-deep .nav .open>a:focus,
            :host ::ng-deep .nav .open>a:hover {
              border: 1px solid #c4c4c4;
            }

            @media (min-width: 769px) {
              :host ::ng-deep .navbar-right {
                margin-right: -5px;
              }
              :host ::ng-deep .navbar-nav>li>a {
                  padding: 5px;
                  margin: 10px 3px 0 0;
              }
              :host ::ng-deep .dropdown-menu>li>a {
                text-align: right;
              }


            }
            @media (max-width: 769px) {
              :host ::ng-deep .dropdown-menu>li>a{
                height: auto!important;
                line-height: normal!important;
                text-align: right;
                width: auto!important;
              }
              :host ::ng-deep .dropdown-menu>li>a:hover {
                color: #fff;
              }
              :host ::ng-deep .dropdown-menu {
                left: unset;
                right: 0px;
              }
            }
          `]
})
export class HeaderComponent implements OnInit {

  searchMobileActive = false;
  user: any

  production = environment.production

  chatEnabled = environment.chat.enabled

  chatVisible = false

  chatLoading = false

  constructor(
    private router: Router,
    public authService: AuthService,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    
    const this$ = this
    $(document).arrive("#rocketchat-iframe", function(el) {
      el.onload = function() {
        this$.chatLoading = false
          this$.chatToggle()
       };

    })
    this.userService.getLoginInfo(true).subscribe(user => {
    this.user = user;
  });

  }

  toggleSearchMobile() {
    this.searchMobileActive = !this.searchMobileActive;

    $('body').toggleClass('search-mobile', this.searchMobileActive);
  }

  onSubmit() {
    this.router.navigate(['/inne/search']);

  }
  onChatToggle() {
    this.chatVisible = true
    const chatWidet = $('.rocketchat-widget')
    if (chatWidet.length) {
      this.chatToggle()
    } else {
      this.chatLoading = true
    }
  }

  chatToggle() {
    const chatWidet = $('.rocketchat-widget')
      chatWidet.toggle('slow', (e) => {
        if (chatWidet.is(':visible')) {
          chatWidet.css('height', '350px')
        } else {
          chatWidet.css('height', '30px')
        }
      })
  }
}

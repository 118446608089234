import { AnalyticsModule } from './../../../../+dashboard/+analytics/analytics.module';
import { UserService } from 'app/+b2b/user/user.service';
import { Component, OnInit, Input, ElementRef, OnDestroy, AfterViewInit  } from '@angular/core';


declare var RocketChat: any;

@Component({
  selector: 'livechat',
  templateUrl: './livechat.component.html',
  styles: []
})
export class LivechatComponent implements OnInit, AfterViewInit, OnDestroy {

  user: any

  constructor(
    private userService: UserService,
    private el: ElementRef) { }

  ngOnInit() {
    this.userService.getLoginInfo(true).subscribe(user => {
      this.user = user
    })
  }

  ngAfterViewInit() {

    import('assets/js/livechat.js').then(() => {
      const user = this.user
       RocketChat(function() {
        this.setGuestName(user.name);
      //   this.onChatEnded(function() {
      //     // do whatever you want
      //     console.log('chat ended');
      // });
      })
    })
  }

  ngOnDestroy() {
    location.reload()
    // document.querySelector('iframe').contentWindow.postMessage({
    //   externalCommand: 'logout',
    // }, '*')
    // // for(let key in localStorage) {
    // //   delete localStorage[key];
    // //   }
    // localStorage.clear()
    // //  $('#rocketchat-iframe').detach()
  }
}

import { VERSION } from 'environments/version';
import { Component, OnInit } from '@angular/core';
import { AppState } from '../../../app.service';

@Component({
  selector: 'sa-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  version = VERSION;
  year = new Date().getFullYear();
  versionServer:any

  constructor(
    private appState: AppState
  ) { }

  ngOnInit() {
    this.appState.getVersion()
      .subscribe(v => this.versionServer = v)
  }

}

import { Injectable } from '@angular/core';
import { CanActivate, Router, RoutesRecognized } from '@angular/router';
import { AuthService } from 'app/+b2b/auth/auth.service';

@Injectable()
export class DirectorGuard implements CanActivate {

    access = false

    constructor(
      private authService: AuthService,
      private router: Router
    ) {
      this.canActivate()
    }
 
    canActivate(): boolean {
      
      if (this.authService.isDirector()) {
        this.access = true
        return true;
      }  else {
        this.access = false
        return false;
      }
    }
}
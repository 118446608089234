import {
  empty as observableEmpty,
  of as observableOf,
  BehaviorSubject,
  Subject,
  Observable
} from 'rxjs';

import { tap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './../auth/auth.service';

import { Injectable } from '@angular/core';

import { JsonApiService } from '../../core/api/json-api.service';
import { B2B_DI_CONFIG } from '../b2b.config';

@Injectable()
export class UserService {
  public user: BehaviorSubject<any>;

  private _users: any;
  private _usersNotRegistered: any;

  public userInfo = {
    username: 'Guest'
  };

  private url = B2B_DI_CONFIG.server.url;
  private printServer = B2B_DI_CONFIG.printServer.url;

  constructor(
    private http: HttpClient,
    private jsonApiService: JsonApiService,
    private authService: AuthService
  ) {
    this.user = new BehaviorSubject({});
  }

  getLoginInfo(notTemp = null): Observable<any> {
    if (localStorage.getItem('user-info') && this.authService.isLoggedIn()) {
      const userTemp = localStorage.getItem('user-temp') ? 1 : 0;

      let userStorage;

      if (notTemp) {
        userStorage = localStorage.getItem('user-info');
      } else {
        userStorage = localStorage.getItem('user-temp')
          ? localStorage.getItem('user-temp')
          : localStorage.getItem('user-info');
      }

      const user$ = observableOf(JSON.parse(userStorage));
      return user$.pipe(
        tap(user => {
        //   user.picture = 'assets/img/avatars/sunny.png';
          this.userInfo = user;
          user.temporary = userTemp;
          this.user.next(user);
        })
      );
    } else {
      return observableEmpty();
    }
  }

  updateProfile() {
    localStorage.setItem('user-info', JSON.stringify(this.user.getValue()));
  }

  getOne(id) {
    return this.http.get<any>(this.url + 'getUsersDetails/' + id + '/');
  }

  getAll(showUsersForId = 0): Promise<any> {
    return this.http
      .get<any>(this.url + 'getUsersList/')
      .pipe(
        map(users => {
          let results = users.map(user => {
            let permissionText: string;
            switch (user.lgn_permission) {
              case 0:
                permissionText = 'klient';
                break;
              case 1:
                permissionText = 'handlowiec';
                break;
              case 2:
                permissionText = 'dyr. handlowy';
                break;
              case 3:
                permissionText = 'administrator';
                break;
            }

            return {
              id: user.Id,
              name: user.Name,
              code: user.Shortcut,
              nip: user.NIP,
              regon: user.REGON,
              discount: user.Rabat,
              vat: user.VATPayerActive,
              permission: user.lgn_permission,
              permissionText: permissionText,
              LimitAmount: user.LimitAmount,
              LimitFlag: user.LimitFlag,
              login: user.lgn_login,
              password: user.lgn_pass,
              phone: user.lgn_phone,
              discountCash: user.lgn_rabatGot,
              discountTrans: user.lgn_rabatTrans,
              discountTransQuota: user.lgn_rabatTransKw,
              alpolId: user.AlpolId,
              manager: user.Handlowiec,
              managerId: user.HandlowiecId,
              region: user.RegionFull,
              network: user.Siec,
              parent: user.lgn_pod_id,
              parentText: user.pod_Shortcut,
              foreignBrand: user.lgn_mwgroup_id,
              invoicesAccess: user.lgn_invoices,
              noConfirm: user.lgn_noconfirm,
              emailSend: user.lgn_emailSendPriceAccept,
              managerAttr: user.lgn_wymiar_element_id,
              managerAttrName: user.lgn_wymiar_element_name,
              bonusEnabled: user.bonusEnabled,
              inactive: user.lgn_nieaktywny
            };
          });
          if (showUsersForId) {
            results = results.filter(data => data.managerId === showUsersForId)
          }
          return results
        })
      )
      .toPromise()
      .then(data => (this._users = data));
  }

  getNotRegistered(): Promise<any> {
    return this.http
      .get<any>(this.url + 'getUsersWithoutAccountList/')
      .toPromise();
  }


  getBonuses(userId: number, year: string): Observable<any> {
    const y = year ?  year + '/' : ''
    return this.http
      .get<any>(this.url + 'getBonusUserConfig/' + userId + '/' + y)
  }

  updateBonuses(userId: number, bonuses): Observable<any> {
    return this.http
      .post<any>(this.url + 'updateBonusUserConfig/', bonuses)
  }


  getUserPermissions(idUser: number): Observable<any> {
    return this.http
      .get<any>(this.url + 'getUsersPermissionsByUserId/' + idUser + '/')
  }

  updatePermission(userId, idPermission, status) {
    return this.http.post(this.url + 'setUsersPermissions/', { userId: userId, idPermission: idPermission, status: status})
  }



  getRegions(): Promise<any> {
    return this.http
      .get<any>(this.url + 'getRegiony/')
      .pipe(
        map(regions =>
          regions.map((region, index) => {
            return {
              value: region,
              text: region
            };
          })
        )
      )
      .toPromise();
  }

  getFromNetwork(net, format = null): Promise<any> {
    let results = this.http.get<any>(
      this.url + 'getUsersFromNetwork/' + net + '/'
    );
    if (format) {
      results = results.pipe(
        map(addresses =>
          addresses.map(address => {
            return {
              value: address.Id,
              text:
                address.Name +
                ' - ' +
                address.Place +
                ', ' +
                address.PostCode +
                ', ul.' +
                address.Street +
                ' ' +
                address.ApartmentNo +
                ' ' +
                address.HouseNo
            };
          })
        )
      );
    }
    return results.toPromise();
  }

  getUserDetailsFromNetwork(id) {
    return this.http.get<any>(
      this.url + 'getUserDetailsFromNetwork/' + id + '/'
    );
  }

  getParents(): Promise<any> {
    return this.http.get<any>(this.url + 'getUsersListWithNip/').toPromise();
  }

  changeUserPassword(password) {
    return this.http.post(
      this.url + 'changeUserPassword/' + password + '/',
      null
    );
  }

  update(user, type = 'update') {
    const userRemote = {
      Id: user.id,
      lgn_login: user.login,
      lgn_pass: user.password,
      lgn_phone: user.phone,
      lgn_permission: user.permission,
      lgn_pod_id: user.parent,
      lgn_rabatGot: user.discountCash,
      lgn_rabatTrans: user.discountTrans,
      lgn_rabatTransKw: +user.discountTransQuota,
      lgn_mwgroup_id: user.foreignBrand,
      lgn_invoices: user.invoicesAccess,
      lgn_noconfirm: user.noConfirm,
      lgn_emailSendPriceAccept: user.emailSend,
      lgn_wymiar_element_id: +user.managerAttr,
      lgn_nieaktywny: +user.inactive
    };
    return this.http.post(this.url + 'saveUser/' + type + '/', userRemote);
  }

  get users(): any {
    return this._users;
  }

  getManagersAttr(): Promise<any> {
    return this.http.get<any>(this.url + 'getHandlowcyWymiarList/').pipe(
      map(managers =>
        managers.map(m => {
          return {
            value: m.id,
            text: m.name
          };
        })
      )).toPromise();
  }


  getManagersBonuses(year) {
    return this.http.get<any>(this.url + 'getUsersPremiaReports/1/' + year + '/').toPromise();
  }
  getDirectorsBonuses(year) {
    return this.http.get<any>(this.url + 'getUsersPremiaReports/2/' + year + '/').toPromise();
  }
  getUsersBonuses(year) {
    return this.http.get<any>(this.url + 'getBonusUserGeneratedList/' + year + '/').toPromise();
  }
  getUsersBonusesYear(year) {
    return this.http.get<any>(this.url + 'getBonusUserGeneratedListYear/' + year + '/').toPromise();
  }

  generateBonuses(year, quarter) {
    return this.http.post(this.url + 'generateBonuses/' + year + '/' + quarter + '/', null);
  }
  generatePremium(year, month) {
    return this.http.post(this.url + 'generateRozliczenie/' + year + '/' + month + '/', null);
  }

  generateSettlement(year, quarter) {
    return this.http.post(this.url + 'generateRozliczenieHandlowcyKwartalnie/' + year + '/' + quarter + '/', null);
  }

  printManagerBonuses(id, year, month) {
    return this.http.get<Blob>(this.printServer + '/premie/' + id + '/' + year + '/' + month,
    {responseType: 'blob' as 'json'});
  }
  printUserBonuses(id, year, quarter) {
    return this.http.get<Blob>(this.printServer + '/bonus/' + id + '/' + year + '/' + quarter,
    {responseType: 'blob' as 'json'});
  }
  printUserBonusesYear(id, year) {
    return this.http.get<Blob>(this.printServer + '/bonus/' + id + '/' + year + '/0',
    {responseType: 'blob' as 'json'});
  }

  updateUserBonus(userId, year, quarter, value) {
    return this.http.post(
      this.url + 'setBonusUserGeneratedPotracenieOdsetkiKwota/' + year + '/' + quarter + '/' + userId + '/' + value + '/', null
      );
  }
}

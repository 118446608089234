import {
  Directive,
  ElementRef,
  OnInit,
  AfterViewInit,
  AfterViewChecked
} from '@angular/core';

import {
  LayoutService
} from "../../layout/layout.service";
import {
  Router,
  NavigationEnd
} from "@angular/router";
import {
  Subscription
} from 'rxjs';


declare var $: any;

@Directive({
  selector: '[saSmartMenu]'
})
export class SmartMenuDirective implements OnInit, AfterViewInit, AfterViewChecked {

  private $menu: any;
  private menuLengthTmp: number
  private layoutSub: Subscription;
  private routerSub: Subscription;

  constructor(
    private menu: ElementRef,
    private router: Router,
    public layoutService: LayoutService
  ) {
    this.$menu = $(this.menu.nativeElement);
  }

  ngOnInit() {
    this.layoutSub = this.layoutService.subscribe((store) => {
      this.processLayout(store)
    });

    // collapse menu on mobiles

  }

  ngAfterViewInit() {
    this.prepareMenuTree()
  }

  ngAfterViewChecked() {
    /* sprawdza czy sa nowe elementy w menu i je przygotowuje */
    if (this.$menu.find('li').length !== this.menuLengthTmp) {
      this.prepareMenuTree()
    }
  }

  ngOnDestroy() {
    this.layoutSub.unsubscribe();
  }

  private prepareMenuTree () {
    this.$menu.find('li').each((i, li) => {
      let $menuItem = $(li);
      let $a = $menuItem.find('>a');

      let sign = $('<b class="collapse-sign"><em class="fa fa-plus-square-o"/></b>')

      if ($a.parent().hasClass('open')) {
        sign = $('<b class="collapse-sign"><em class="fa fa-minus-square-o"/></b>')
      }


        $a.off('click').on('click', (e) => {
          this.toggle($menuItem);
          e.stopPropagation();
          if (this.layoutService.store.mobileViewActivated && $(e.currentTarget).parent().find('ul').length === 0) {
            let collapseFlag = true
            const par = $(e.currentTarget).parent()
            const par3 = par.parent().parent()
            const par9 = par3.parent().parent().parent().parent().parent()

            if (par.hasClass('categories-menu') || par3.hasClass('categories-menu') || par9.is('ul')) {
              collapseFlag = false
            }
            this.layoutService.onCollapseMenu(collapseFlag)

          }
          return false;
        })


      if ($a.parent().find('ul').length) {
        if (!$a.find('.collapse-sign').length) {
          $a.append(sign)
        }
      }


     })

    $('[routerLink]', this.$menu).on('click', () => {
    if (this.layoutService.store.mobileViewActivated) {
      this.layoutService.onCollapseMenu()
    }
  })

    setTimeout(()=>{
      this.processLayout(this.layoutService.store)
    }, 200)
    
    this.menuLengthTmp = this.$menu.find('li').length

  }


  private processLayout = (layoutStore) => {
    if (layoutStore.menuOnTop) {
      this.$menu.find('li.open').each((i, li) => {
        this.toggle($(li), false)
      })
    } else {
      this.$menu.find('li.active').each((i, li) => {
        $(li).parents('li').each((j, parentLi) => {
          // this.toggle($(parentLi), true)
        })
      })
    }

    if (layoutStore.mobileViewActivated) {
      $('body').removeClass("minified");
    }
  };

  private toggle($el, condition = !$el.data('open')) {
    $el.toggleClass('open', condition);

    if (condition) {
      $el.find('>ul').slideDown();
    } else {
      $el.find('>ul').slideUp();
    }

    $el.find('>a>.collapse-sign>em')
      .toggleClass('fa-plus-square-o', !condition)
      .toggleClass('fa-minus-square-o', condition);

    $el.data('open', condition);

    if (condition) {
      $el.siblings('.open').each((i, it) => {
        let sib = $(it);
        this.toggle(sib, false)
      })
    }
  }

}
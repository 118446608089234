import { pairwise ,  filter } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { CanActivate, Router, RoutesRecognized } from '@angular/router';
import { AuthService } from 'app/+b2b/auth/auth.service';



@Injectable()
export class AuthGuard implements CanActivate {

  private previousRoute = '/'

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    this.router.events.pipe(
      filter(e => e instanceof RoutesRecognized),
      pairwise(),)
      .subscribe((event: any[]) => {
          this.previousRoute = event[0].urlAfterRedirects
        }
      )
  }

  canActivate(): boolean {
    let returnUrl = ''
    if (this.authService.isLoggedIn()) {
      return true;
    }  else {

      if (this.isToken()) {
        this.clearStorage()
      }
      if (this.previousRoute && !this.previousRoute.includes('logowanie')) {
        returnUrl = '?return=' + this.previousRoute
      }
      this.router.navigateByUrl('/auth/logowanie' + returnUrl);
      return false;
    }
  }

  logout(): void {
    this.clearStorage()
    this.router.navigateByUrl('/auth/logowanie?logout=true')
    this.authService.logout()
  }

  private clearStorage() {
    localStorage.removeItem('access_token')
    localStorage.removeItem('user-info')
    localStorage.removeItem('user-temp')
    localStorage.removeItem('ngx_permissions')

    localStorage.removeItem('cart')

    /** filtry na liscie klientow */
    localStorage.removeItem('ngx_users-list.permissionsFilterModel')
    localStorage.removeItem('ngx_users-list.regionsFilterModel')

    /** filtry na liscie kart */
    localStorage.removeItem('ngx_discount-list.statusFilterModel')
    localStorage.removeItem('ngx_discount-list.userFilterModel')
    localStorage.removeItem('ngx_discount-list.productFilterModel')
    localStorage.removeItem('ngx_discount-list.regionFilterModel')

    const orderListStorage = JSON.parse(localStorage.getItem('ngx_orders-list.tableControls'))

    if (orderListStorage) {
      orderListStorage.showall = false
      localStorage.setItem('ngx_orders-list.tableControls', JSON.stringify(orderListStorage))
    }

  }

  private isToken() {
    return localStorage.getItem('access_token')
  }
}
import { ChangeDetectorRef, Input } from '@angular/core';
import { UserService } from './../user.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

declare var $: any;

@Component({
  selector: 'user-form',
  templateUrl: './user-form.component.html',
  styles: [`
            :host ::ng-deep .panel-heading.card-header {width: 184px; background-color: unset;}
            :host ::ng-deep .panel {box-shadow: unset; -webkit-box-shadow: unset}
            :host ::ng-deep .panel.card.panel-default {
              border: unset;
              background-color: unset;
            }
            :host ::ng-deep .pull-right.fa {
                padding-top: 4px
            }
            :host ::ng-deep .panel-heading.card-header {width: unset;}
            :host ::ng-deep .smart-accordion-default.panel-group .panel-title a {
              padding: 8px 0;
              font-size: 16px;
              font-weight: 300;}
          `]
})
export class UserFormComponent implements OnInit {
  @Output('validateOptions') validateOptions = new EventEmitter();
  @Output('formValues') formValues = new EventEmitter();
  @Input('cart') cart
  @Input('personalCollect') personalCollect


  isNewAddress = false


  user = {
    fname: '',
    lname: '',
    email: '',
    phone: '',
  }

  newAddress = {
    name: '',
    address: '',
    code: '',
    city: ''
  }

  

  addressValidationOptions = {
    rules : {
      city : {
        required : true
      },
      code : {
        required : true,
        // postal_code : true
      },
      address : {
        required : true
      }

  },
      // Messages for form validation
    messages : {
      city : {
        required : 'Podaj miasto'
      },
      code : {
        required : 'Wpisz kod pocztowy',
        postal_code : 'Podaj prawidłowy kod pocztowy'
      },
      address : {
        required : 'Podaj adres'
      }
    }
  };

  constructor(private userService: UserService,private ref: ChangeDetectorRef ) {
   }
  
  ngOnInit() {
    // this.validateOptions.emit({ options: this.validationOptions, state: 'add'})
    // this.userService.getLoginInfo().subscribe(user => {
    //   if (user) {
    //     this.user = user
    //   }
    // })

    
  }
  

  switchNewAddress() {
    this.isNewAddress = !this.isNewAddress

    if (this.isNewAddress === true) {
      this.validateOptions.emit({ options: this.addressValidationOptions, state: 'add'})
    } else {
      this.validateOptions.emit({ options: this.addressValidationOptions, state: 'remove'})
    }
  }



}

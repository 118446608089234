import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayFilter',
})
export class ArrayFilterPipe implements PipeTransform {

  transform(items: any[], field: any, value: any): any[] {
    if (!items) return [];
    if (!value) return items;
    return items.filter(it => it[field] !== value);
  }
}


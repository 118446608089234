import {NgModule, APP_INITIALIZER} from "@angular/core";
import {CommonModule} from "@angular/common";
import {I18nModule} from "../../i18n/i18n.module";
import {BigBreadcrumbsComponent} from "./big-breadcrumbs.component";
import {MinifyMenuComponent} from "./minify-menu.component";
import {NavigationComponent} from "./navigation.component";
import {SmartMenuDirective} from "./smart-menu.directive";
import {RouterModule} from "@angular/router";

import { CategoriesMenuComponent } from "app/+b2b/categories/categories-menu/categories-menu.component";
import { CategoryService } from "app/+b2b/categories/category.service";
import { ChatModule } from "app/+b2b/chat/chat.module";
import { UserModule } from "app/+b2b/user/user.module";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    I18nModule,
    UserModule,
    ChatModule
  ],
  declarations: [
    BigBreadcrumbsComponent,
    MinifyMenuComponent,
    NavigationComponent,
    SmartMenuDirective,
    CategoriesMenuComponent
  ],
  exports: [
    BigBreadcrumbsComponent,
    MinifyMenuComponent,
    NavigationComponent,
    SmartMenuDirective,
  ],
  providers:[
    CategoryService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (categoryService: CategoryService) => function() {return categoryService.getAll()},
    //   deps: [CategoryService],
    //   multi: true
    // }
  ]
})
export class NavigationModule{}

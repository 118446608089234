import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { B2B_DI_CONFIG } from '../b2b.config';
import { isArray } from 'util';


@Injectable()
export class PermissionGuard implements CanActivate {

  private url = B2B_DI_CONFIG.server.url;

  constructor(
    private http: HttpClient,
    private router: Router,
    private jwtHelperService: JwtHelperService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    const code = route.data['code']

    if (code === 'SERVICE_WORK') {
      const isServiceWork = await this.isServiceWork().toPromise()

      if (isServiceWork) {
        this.router.navigate(['/aktualnosci']);
        return false
      }
      
      return true
    }


    const permissions = await this.getAllPermissions().toPromise()


    const permission = this.getIdPermission(code, permissions)



    if (permission) {
      if (Array.isArray(permission)) {
        const canAct = permission.map(p => this.hasPermission(p.idPermission, p.minUserPermission))

        if (canAct.includes(true)) {
          return true
        } else {
          return false
        }
      } else if (this.hasPermission(permission.idPermission, permission.minUserPermission)) {

        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // ***********do zastosowania w komponencie ************ //

  async canActivateCustom(code, permissions = null): Promise<boolean> {

    if (!permissions) {
      permissions = await this.getAllPermissions().toPromise()
    }


    const permission = this.getIdPermission(code, permissions)

    if (permission) {
      if (Array.isArray(permission)) {
        const canAct = permission.map(p => this.hasPermission(p.idPermission, p.minUserPermission))


        if (canAct.includes(true)) {
          return true
        } else {
          return false
        }
      } else if (this.hasPermission(permission.idPermission, permission.minUserPermission)) {

        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }

  }

  isServiceWork() {
    return this.http.get<any>(this.url + 'IsServiceWork/')
  }

  private getIdPermission(code, permissions): any {

    const permission = Array.isArray(code) ? permissions.filter(p => code.includes(p.kod)) : permissions.filter(p => p.kod === code)[0]

    if (permission) {
      return permission
    }

    return null
  }

  // public getAllPermissions() {
  //   return this.userService.getPermissions().toPromise()
  // }

  getAllPermissions(): Observable<any> {
    return this.http
      .get<any>(this.url + 'getUsersPermissionsDefs/')
  }


  private hasPermission(id: number, minPermission: number) {
    const token: string = this.jwtHelperService.tokenGetter()
    if (token) {
      const user = this.jwtHelperService.decodeToken(token)
      const permissionTable = user.permissionTable.map(Number)
      // *****  id === 34  wyjatek dla "DOSTEP_DO_B2B" ************ \\
      if (permissionTable.includes(id) && (((minPermission > 0 || id === 34) && user.permission >= minPermission) || (user.permission == 0 && minPermission == 0))) {
        return true
      }
    }

    return false
  }

  // async isSuperAdminUser(name): Promise<boolean> {
  //   return name === 'Osypiuk Maciej' ? true : false
  // }
}
export const environment = {
  production: false,
  server: {
    url: 'https://api.b2b.dev.dev64.pl/'
  },
  printServer: {
    url: 'http://192.168.100.19:8086/'
  },
  chat: {
    enabled: false
  }
};

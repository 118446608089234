import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private state$ = new BehaviorSubject<boolean>(false);
  private reloadAfterError = false;

  constructor() { }

  reloadAfterErrorDone() {
    this.reloadAfterError = true;
    this.state$.next(this.reloadAfterError);
  }

  reloadAfterErrorReset() {
    this.reloadAfterError = false;
    this.state$.next(this.reloadAfterError);
  }

  finish() {
    this.state$.complete();
  }

  get state(): Observable<boolean> {
    return this.state$.asObservable();
  }
}

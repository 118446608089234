import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CategoryService } from 'app/+b2b/categories/category.service';

@Component({
  selector: 'app-b2b-categories-menu',
  templateUrl: './categories-menu.component.html'
})
export class CategoriesMenuComponent implements OnInit {
  @Output() categoriesDataLoaded = new EventEmitter();

  categories: any[]

  constructor(private categoryService: CategoryService) { }

  async ngOnInit() {
    const categories = await this.categoryService.getAll()
    if (categories) {
      this.categories = this.categoryService.categories
    }
    
  }

}

import { AccordionModule } from 'ngx-bootstrap';
import { FormsModule } from '@angular/forms';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginInfoComponent } from './login-info/login-info.component';
import { LogoutComponent } from './logout/logout.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { SmartadminValidationModule } from '../../shared/forms/validation/smartadmin-validation.module';
import { userRouting } from './user.routing';
import { UserFormComponent } from './user-form/user-form.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { BlogService } from '../blog/blog.service';

@NgModule({
  imports: [
    CommonModule,
    SmartadminValidationModule,
    userRouting,
    FormsModule,
    AccordionModule.forRoot(),

  ],
  declarations: [
    LoginInfoComponent,
    LogoutComponent,
    UserProfileComponent,
    UserFormComponent,
    ChangePasswordComponent
  ],
  exports: [
    LoginInfoComponent,
    LogoutComponent,
    UserFormComponent
  ],
  providers: [BlogService]
})
export class UserModule {}

import { AuthGuard } from './../../auth/auth-guard.service';
import { NotificationService } from './../../../shared/utils/notification.service';
import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import { AuthService } from 'app/+b2b/auth/auth.service';

declare var $:any;

@Component({
  selector: 'sa-logout',
  template: `
<div id="logout" (click)="isLogged()" class="btn-header transparent pull-right">
        <span> <a routerlink="/auth/logowanie" title="{{ isLoggedIn() ? 'Wyloguj się' : 'Zaloguj się' }}" data-action="userLogout"
                  data-logout-msg="You can improve your security further after logging out by closing this opened browser">
                  <i class="fa {{ isLoggedIn() ? 'fa-sign-out' : 'fa-sign-in' }}"></i></a> </span>
    </div>
  `,
  styles: []
})
export class LogoutComponent implements OnInit {

  constructor(private router: Router,
              private notificationService: NotificationService,
              private authService: AuthService,
              private authGuard: AuthGuard
            ) { }

  isLogged() {
    this.isLoggedIn() ? this.showPopup() : this.router.navigate(['/auth/logowanie'])
  }

  showPopup() {
    this.notificationService.smartMessageBox({
      title : "<i class='fa fa-sign-out txt-color-orangeDark'></i> Czy na pewno chcesz się wylogować<span class='txt-color-orangeDark'><strong>" + $('#show-shortcut').text() + "</strong></span> ?",
      content : "",
      buttons : '[Nie][Tak]'

    }, (ButtonPressed) => {
      if (ButtonPressed == "Tak") {
        this.logout()
      }
    });
  }

  isLoggedIn() {
    return this.authService.isLoggedIn()
  }

  logout() {
    this.authGuard.logout();
  }

  ngOnInit() {

  }



}

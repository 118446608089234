import { AuthGuard } from './+b2b/auth/auth-guard.service';
/**
 * Created by griga on 7/11/16.
 */

import { Routes, RouterModule, CanActivate } from '@angular/router';
import { MainLayoutComponent } from './shared/layout/app-layouts/main-layout.component';
import { AuthLayoutComponent } from './shared/layout/app-layouts/auth-layout.component';
import { ModuleWithProviders } from '@angular/core';
import { SmartAdminRoutes } from './smartadmin.routing';

export const appRoutes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    data: { pageTitle: 'B2B' },
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('app/+b2b/b2b.module').then(m => m.B2bModule),
        data: { pageTitle: '' }
      },
    ]
    // children: SmartAdminRoutes
  },
  {
    path: '',
    loadChildren: () => import('app/+b2b/auth/auth.module').then(m => m.AuthModule),
    data: { pageTitle: 'Logowanie' }
  },
  {
    path: 'auth',
    loadChildren: () => import('app/+b2b/auth/auth.module').then(m => m.AuthModule),
    data: { pageTitle: 'Logowanie' }
  },
  {
    path: '**',
    redirectTo: 'inne/error404'
  }
];

export const appRouting: ModuleWithProviders = RouterModule.forRoot(appRoutes, {
  useHash: true
});

import { NgModule, InjectionToken } from '@angular/core';
import { environment } from '../../environments/environment';

export let B2B_CONFIG = new InjectionToken<B2bConfig>('app.config');

export class B2bConfig {

    session: any
    products: any
    mail: any
    server: any
    printServer: any

}
export const B2B_DI_CONFIG: B2bConfig = {
    server: {
        url: environment.server.url
    },
    printServer: {
        url: environment.printServer.url
    },
    products: {
        itemsPerPageTiles: 12,
        itemsPerPageList: 10
    },
    session: {
        products: {
            view: {
                isTiles: true
            }
        }
    },
    mail: {
        host: 'poczta.it64.pl',
        port: 587,
        from: {
            address: 'system@hosting.it64.pl',
            name: 'Dev64.pl - B2B'
        },
        username: '',
        password: '',
        encryption: false
    }
  };

  @NgModule({
    providers: [{
      provide: B2B_CONFIG,
      useValue: B2B_DI_CONFIG
    }]
  })
  export class B2bConfigModule { }

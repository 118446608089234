
import { throwError as observableThrowError, of as observableOf, Observable } from 'rxjs';

import { share, catchError, tap } from 'rxjs/operators';
import { AuthGuard } from './../auth-guard.service';
import { NotificationsService } from 'angular2-notifications';

import { AuthService } from 'app/+b2b/auth/auth.service';
import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { RequestCacheService } from '../request-cache.service';
import { Router } from '@angular/router';
import { StateService } from 'app/store/state.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    
    reloadAfterError: boolean;
    reloadAfterError$: Observable<boolean>;

    private authService: AuthService;
    private authGuard: AuthGuard
    private notificationsService: NotificationsService

    constructor(
        private injector: Injector,
        private router: Router,
        private requestCacheService: RequestCacheService,
        private stateService: StateService
    ) { 
        this.stateService.state.subscribe(state => {
            this.reloadAfterError = state;
          });
        this.reloadAfterError$ = this.stateService.state;
    }

    setHeaders(request, token) {
        return request.clone({
            setHeaders: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
    }

    reloadCurrentRoute() {
        if (!this.reloadAfterError) {
            const currentUrl = this.router.url;
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate([currentUrl]);
                this.stateService.reloadAfterErrorDone()

                setTimeout(() => {
                    this.stateService.reloadAfterErrorReset()
                }, 3000);
            });
        }

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.authService = this.injector.get(AuthService);
        this.authGuard = this.injector.get(AuthGuard);
        this.notificationsService = this.injector.get(NotificationsService);
        // add authorization header with jwt token if available
        const token = localStorage.getItem('access_token');
        if (token) {
            request = this.setHeaders(request, token)
        }

        const nextReq = request.clone({
            headers: request.headers.set('Cache-Control', 'no-cache')
                .set('Pragma', 'no-cache')
                .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
                .set('If-Modified-Since', '0')
        });

        const cachedResponse = request.method === 'GET' ? this.requestCacheService.get(nextReq) : null

        //   console.log('cachedResponse: ', cachedResponse);
        return this.sendRequest(nextReq, next);

    }

    private sendRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    this.requestCacheService.put(req, event);
                }
            }),
            catchError((err: HttpErrorResponse) => {

                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        this.notificationsService.error(
                            'Błąd autoryzacji',
                            'wystąpił błąd autoryzacji, zaloguj się ponownie',
                            {
                                timeOut: 4000,
                                showProgressBar: true,
                                pauseOnHover: false,
                                clickToClose: true,
                                maxLength: 10
                            })
                        this.authGuard.logout()
                    } else if (err.status === 400) {
                        this.notificationsService.error(
                            'Błąd serwera',
                            'wystąpiła błędna odpowiedź z serwera, powiadom administratora o błędzie',
                            {
                                timeOut: 10000,
                                showProgressBar: true,
                                pauseOnHover: false,
                                clickToClose: true,
                                maxLength: 10
                            })
                    } else if (err.error) {
                        if (typeof err.error === 'string') {
                            this.notificationsService.error(
                                'Błąd',
                                err.error,
                                {
                                    timeOut: 4000,
                                    showProgressBar: true,
                                    pauseOnHover: false,
                                    clickToClose: true,
                                    maxLength: 10
                                })
                            // return Observable.throw(err.error)
                        } else if (err.error.ExceptionMessage) {
                            this.notificationsService.error(
                                'Błąd',
                                err.error.ExceptionMessage,
                                {
                                    timeOut: 4000,
                                    showProgressBar: true,
                                    pauseOnHover: false,
                                    clickToClose: true,
                                    maxLength: 10
                                })
                        } else {
                            this.notificationsService.error(
                                'Błąd połączenia',
                                'wystąpił błąd połączenia z serwerem, spróbuj za chwilę',
                                {
                                    timeOut: 4000,
                                    showProgressBar: true,
                                    pauseOnHover: false,
                                    clickToClose: true,
                                    maxLength: 10
                                })
                            // return Observable.throw('Błąd połączenia z serwerem, spróbuj za chwilę')
                        }
                        // throw new Error(err.message);
                    } else {
                        this.notificationsService.error(
                            'Błąd',
                            err.message,
                            {
                                timeOut: 4000,
                                showProgressBar: true,
                                pauseOnHover: false,
                                clickToClose: true,
                                maxLength: 10
                            })
                        // return Observable.throw(err.message)
                    }
                } else if (err) {
                    this.notificationsService.error(
                        'Błąd',
                        err,
                        {
                            timeOut: 4000,
                            showProgressBar: true,
                            pauseOnHover: false,
                            clickToClose: true,
                            maxLength: 10
                        })
                    // return Observable.throw(err)
                } else {
                    // The backend returned an unsuccessful response code.
                    // The response body may contain clues as to what went wrong,
                    this.notificationsService.error(
                        'Błąd połączenia',
                        'wystąpił błąd połączenia z serwerem, spróbuj za chwilę',
                        {
                            timeOut: 4000,
                            showProgressBar: true,
                            pauseOnHover: false,
                            clickToClose: true,
                            maxLength: 10
                        })
                    //   return Observable.throw("Błąd połączenia z serwerem, spróbuj za chwilę")
                    // return Observable.of(new HttpResponse({body: [{name: `Błąd: ${err.status}, ${err.error}`}]}))
                    //   console.error(`Błąd: ${err.status}, ${err.error}`);
                }

                // ...optionally return a default fallback value so app can continue (pick one)
                // which could be a default value (which has to be a HttpResponse here)
                // return Observable.of(new HttpResponse({body: [{name: "Default value..."}]}));
                // or simply an empty observable
                // return Observable.of(new HttpResponse({body: [{name: "Błąd połączenia"}]}))
                this.reloadCurrentRoute()
                return observableThrowError('błąd')
            })
        )
    }
}
import { AuthGuard } from './../../auth/auth-guard.service';
import { AfterViewInit } from '@angular/core';
import { UserService } from 'app/+b2b/user/user.service';
import { Component, OnInit } from '@angular/core';
import {
  NotificationsService,
  SimpleNotificationsComponent
} from "angular2-notifications";

declare var $: any;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit, AfterViewInit {

  model = {
    password: '',
    passwordConfirm: ''
  }


  validationOptions = {
    rules: {
      password: {
        required: true,
        minlength: 5
      },
      passwordconfirm: {
        required: true,
        equalTo: '#password'
      }
    },

    // Messages for form validation
    messages: {
      password: {
        required: 'Podaj nowe hasło',
        minlength: 'Hasło za krótkie, minimalna ilość znaków - 5'
      },
      passwordconfirm: {
        required: 'Powtórz nowe hasło',
        equalTo: 'Powtórzone hasło różni się',
      }
    }
  }

  constructor(
    private userService: UserService,
    private notificationsService: NotificationsService,
    private authGuard: AuthGuard
  ) { }

  ngOnInit() {
  }


  ngAfterViewInit() {

      setTimeout(() => {
        this.addRules(
          this.validationOptions.rules,
          this.validationOptions.messages
        );
      }, 1000);

  }
  changePassword(form) {
    if ($(form).valid() === true) {
      this.userService.changeUserPassword(this.model.password)
      .subscribe(() => {
        this.notificationsService.success(
          'Informacja',
          'Hasło zostało zmienione, za chwile nastąpi przekierowanie do strony logowania',
          {
            timeOut: 2000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: false,
            maxLength: 10
          }
        );
        setTimeout(() => {
          this.authGuard.logout()
        }, 2500);
      })
    }
  }

  addRules(rulesObj, messagesObj) {
    for (const item of Object.keys(rulesObj)) {
      $('#' + item).rules('add', rulesObj[item]);
      $('#' + item).rules('add', { messages: messagesObj[item] });
    }
  }

}

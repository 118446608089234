import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { NotificationsService, SimpleNotificationsComponent } from "angular2-notifications";

declare var $: any;

@Component({
  selector: "user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.css"]
})
export class UserProfileComponent implements OnInit {

  validationOptions = {};

  constructor(
    private notificationsService: NotificationsService
  ) {}

  ngOnInit() {}

  getValidationOptions(emit) {
    this.validationOptions = emit;
  }

  onSubmit(form) {
    console.log(this.validationOptions);
    // if ($(form).valid() === true) {
    //   this.userService.updateProfile();
    //   this.notificationsService.success('Informacja', 'Dane zostały zaktualizowane', {
    //     timeOut: 2000,
    //     showProgressBar: true,
    //     pauseOnHover: false,
    //     clickToClose: false,
    //     maxLength: 10
    //   })
    // }
  }
}

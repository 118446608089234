import { RequestCacheService } from './+b2b/auth/request-cache.service';
import { PermissionGuard } from './+b2b/auth/permission-guard.service';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { ManageStrictGuard } from './+b2b/auth/manage-strict-guard.service.';
import { AdminGuard } from './+b2b/auth/admin-guard.service';
import { DirectorGuard } from './+b2b/auth/director-guard.service';
import { SpinnerModule } from 'angular2-spinner/dist';
import { SharedModule } from './+b2b/shared.module';
import { DiscountSelectComponent } from './+b2b/tools/custom/discount-select/discount-select.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from 'app/+b2b/auth/_helpers/jwt.interceptor';

import { NgModule, ApplicationRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule } from '@angular/forms';

/*
 * Platform and Environment providers/directives/pipes
 */
import { appRouting } from './app.routing'
// App is our top level component
import { AppComponent } from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { AppState, InternalStateType } from './app.service';

// Core providers
import {CoreModule} from "./core/core.module";
import {SmartadminLayoutModule} from "./shared/layout/layout.module";


import { ModalModule } from 'ngx-bootstrap/modal';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';

import { UserService } from 'app/+b2b/user/user.service';
import { AuthGuard } from './+b2b/auth/auth-guard.service';
import { ManageGuard } from './+b2b/auth/manage-guard.service';
import { WebStorageModule } from 'ngx-store';
import { DirectorStrictGuard } from './+b2b/auth/director-strict-guard.service';
import { DeviceDetectorModule, DeviceDetectorService } from 'ngx-device-detector';

// Application wide providers
const APP_PROVIDERS = [
  ...APP_RESOLVER_PROVIDERS,
  AppState
];

type StoreType = {
  state: InternalStateType,
  restoreInputValues: () => void,
  disposeOldHosts: () => void
};


/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */


@NgModule({
  bootstrap: [ AppComponent ],
  declarations: [
    AppComponent
  ],
  imports: [ // import Angular's modules
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ModalModule.forRoot(),
    CoreModule,
    SimpleNotificationsModule.forRoot({
      preventDuplicates: true,
      preventLastDuplicates: true,
      maxStack: 3
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: getToken,
        whitelistedDomains: ['localhost:4200'],
        blacklistedRoutes: ['localhost:4200/auth/']
      }
    }),
    SmartadminLayoutModule,
    appRouting,
    SpinnerModule,
    WebStorageModule,
    DeviceDetectorModule
  ],
  providers: [ // expose our Services and Providers into Angular's dependency injection
    // ENV_PROVIDERS,
    APP_PROVIDERS,
    UserService,
    AuthGuard,
    ManageGuard,
    DirectorGuard,
    AdminGuard,
    ManageStrictGuard,
    DirectorStrictGuard,
    PermissionGuard,
    RequestCacheService,
    DeviceDetectorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
  ]
})
export class AppModule {
  constructor(public appRef: ApplicationRef, public appState: AppState) {}


}

export function getToken() {
  return localStorage.getItem('access_token');
}

